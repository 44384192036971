import actions from './actions';
import { getTimeFormat } from '../Helpers';
import { getClientesFromDevices } from '../lib/calculoClientes.js';
import moment from 'moment';
import { notification, Modal } from 'antd';

/*const warningNoti = e => {
  notification['warn']({
    message: e.title,
    description: e.text,
    onClick: () => {
      Modal.confirm({
        title: '¿Quieres ir a la ficha del vehículo?',
        onOk: () => {
          window.location.href = e.url;
        },
        okText: 'Sí',
        cancelText: 'No',
      });
    },
    top: 75,
    config: {
      maxCount: 5,
    },
  });
};*/

const initState = {
  online: 0,
  loading: true,
  devices: {
    loading: true,
  },
  auxDevices: {
    loading: true,
  },
  softLoadDevices: {
    loading: true,
  },
  lastChange: {},
  subscription: 0,
  last_imei_subscription: '',
  statsAcums: [],
  loadingAcums: true,
  listRoutes: {
    routes: [],
    dias: [],
    diario: [],
    acum: {},
    loading_routes: true,
    loading_dias: true,
    limitSpeed: 0,
    diario_filter_dia: '',
    stats: {},
  },
  /*List routes para cada tab en la ficha del vehiculo*/
  listRoutesNotis: {
    routes: [],
    dias: [],
    diario: [],
    acum: {},
    loading_routes: false,
    loading_dias: false,
    limitSpeed: 0,
    diario_filter_dia: '',
    stats: {},
  },
  listRoutesGeos: {
    routes: [],
    dias: [],
    diario: [],
    acum: {},
    loading_routes: false,
    loading_dias: false,
    limitSpeed: 0,
    diario_filter_dia: '',
    stats: {},
  },
  listRoutesFuel: {
    routes: [],
    dias: [],
    diario: [],
    acum: {},
    loading_routes: false,
    loading_dias: false,
    limitSpeed: 0,
    diario_filter_dia: '',
    stats: {},
  },
  listRoutesStats: {
    routes: [],
    dias: [],
    diario: [],
    acum: {},
    loading_routes: false,
    loading_dias: false,
    limitSpeed: 0,
    diario_filter_dia: '',
    stats: {},
  },
  listRoutesRiders: {
    routes: [],
    dias: [],
    diario: [],
    acum: {},
    loading_routes: false,
    loading_dias: false,
    limitSpeed: 0,
    diario_filter_dia: '',
    stats: {},
  },
  listRoutesRouteTime: {
    routes: [],
    dias: [],
    diario: [],
    acum: {},
    loading_routes: false,
    loading_dias: false,
    limitSpeed: 0,
    diario_filter_dia: '',
    stats: {},
  },
  listRoutesCustom: {
    routes: [],
    dias: [],
    diario: [],
    loading_routes: false,
    loading_dias: false,
    limitSpeed: 0,
    diario_filter_dia: '',
    stats: {},
  },
  filterNegocio: '',
  filterCliente: '',
  filterVehiculo: '',
  notifications: {
    list: [],
    count: 0,
    loading: true,
  },
  notificationsCli: {
    list: [],
    count: 0,
    loading: true,
  },
  notificationsGraficas: {
    list: [],
    count: 0,
    loading: true,
  },
  notificationsTypes: [],
  alarms: {
    today: {},
    his: {},
    loading: true,
  },
  topbarAlarms: {
    list: [],
    last: {},
    loading: true,
  },
  currentAlarms: {
    list: [],
    last: {},
    loading: true,
  },
  mapAlertImage: null,
  geofences: {
    list: [],
    loading: true,
    updating: false,
  },
  geofencesEvents: {
    list: [],
    loading: true,
  },
  geofencesObject: {
    list: [],
    loading: true,
  },
  centros: {
    list: [],
    loading: true,
    updating: false,
    creating: false,
  },
  conductores: {
    list: [],
    loading: false,
  },
  kmsToday: {
    loading: true,
    datos: [
      {
        number: 0,
        text: 'widget.stickerwidget1.text',
        icon: 'road',
        fontColor: '#ffffff',
        bgColor: '#7266BA',
        loading: true,
      },
      {
        number: 0,
        text: 'widget.stickerwidget2.text',
        icon: 'route',
        fontColor: '#ffffff',
        bgColor: '#42A5F6',
        loading: true,
      },
      {
        number: 0,
        seconds: 0,
        text: 'widget.stickerwidget3.text',
        icon: 'clock',
        fontColor: '#ffffff',
        bgColor: '#7ED320',
        loading: true,
      },
      {
        number: 0,
        text: 'widget.stickerwidget4.text',
        icon: 'gauge-max',
        fontColor: '#ffffff',
        bgColor: '#F75D81',
        loading: true,
      },
    ],
  },
  clientes: [],
  numeroClientes: 0,
  rutaInfo: {},
  imeiQR: '',
  dealers: {
    list: [],
    loading: true,
  },
  routesRider: {
    routes: [],
    loading: true,
  },
  currentTrackerDate: moment(new Date()),
  //Configuración
  configNotifications: [],
  configParamsHttp: [],
  //Talleres
  talleres: {
    list: [],
    loading: true,
  },
  dashboardVisible: [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ],
};

export default function(state = initState, action) {
  switch (action.type) {
    case actions.SET_ONLINE:
      return {
        ...state,
        online: action.data,
      };
    case actions.SET_NEGOCIO:
      return {
        ...state,
        filterNegocio: action.data,
      };
    case actions.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: { ...state.notifications, loading: true },
      };
    case actions.SET_CLIENTE:
      return {
        ...state,
        filterCliente: action.data,
      };
    case actions.SET_VEHTYPE:
      return {
        ...state,
        filterVehiculo: action.data,
      };
    case actions.SET_DIARIO_FILTER_DIA:
      return {
        ...state,
        listRoutes: { ...state.listRoutes, diario_filter_dia: action.data },
      };
    case actions.SET_DASHBOARD_VISIBLE:
      return {
        ...state,
        dashboardVisible: action.data,
      };
    case actions.GET_CURRENT_ALARMS:
      if (action.topbar) {
        return {
          ...state,
          topbarAlarms: {
            list: [],
            last: {},
            loading: true,
          },
          currentAlarms: {
            list: [],
            last: {},
            loading: true,
          },
        };
      } else {
        return {
          ...state,
          currentAlarms: {
            list: [],
            last: {},
            loading: true,
          },
        };
      }
    case actions.GET_DEVICES:
      if (action.cambioClientes) {
        return {
          ...state,
          devices: {},
          loading: true,
          lastChange: {},
          subscription: 0,
          last_imei_subscription: '',
          routes: [],
          numeroClientes: 0,
          auxDevices: { loading: true },
        };
      } else {
        return {
          ...state,
          devices: {},
          loading: true,
          lastChange: {},
          subscription: 0,
          last_imei_subscription: '',
          routes: [],
          numeroClientes: 0,
          auxDevices: { loading: true },
        };
      }
    /*case actions.GET_DIAS:
      return {
        ...state,
        listRoutes:{...state.listRoutes,loading_dias:true,dias:[],diario:[],loading_routes:false,routes:[],acum:{},stats:{}},
        subscription:0,
        last_imei_subscription:'',
      };*/
    case actions.GET_ACUMS:
      return {
        ...state,
        statsAcums: [],
        loadingAcums: true,
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.NOTIFICATIONS_RECEIVED:
      return {
        ...state,
        notifications: { ...action.data, loading: false },
        subscription: 0,
      };
    case actions.NOTIF_TYPES_RECEIVED:
      return {
        ...state,
        notificationsTypes: [...action.data],
        subscription: 0,
      };
    case actions.NOTIFICATIONS_CLI_RECEIVED:
      return {
        ...state,
        notificationsCli: { ...action.data, loading: false },
        subscription: 0,
      };
    case actions.GET_RIDERS:
      return {
        ...state,
        conductores: { list: [], loading: false },
      };
    case actions.GET_CENTROS:
      return {
        ...state,
        centros: { list: [], loading: true },
      };
    case actions.GET_DEALERS:
      return {
        ...state,
        dealers: {
          list: [],
          loading: true,
        },
      };
    case actions.UPDATE_CENTRO:
      return {
        ...state,
        centros: { ...state.centros, loading: true },
      };
    case actions.GET_ALARMS:
      return {
        ...state,
        alarms: { today: {}, his: {}, loading: true },
      };
    case actions.GET_TODAY_KMS:
      return {
        ...state,
        kmsToday: initState.kmsToday,
      };
    case actions.ALARMS_RECEIVED:
      return {
        ...state,
        alarms: { ...action.data, loading: false },
      };
    case actions.GET_ROUTES:
      return {
        ...state,
        listRoutes: {
          ...state.listRoutes,
          loading_dias: false,
          dias: state.listRoutes.dias,
          diario: state.listRoutes.diario,
          loading_routes: true,
          routes: [],
          acum: state.listRoutes.acum,
          stats: state.listRoutes.stats,
        },
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.GET_ROUTES_RIDERS:
      return {
        ...state,
        listRoutes: {
          ...state.listRoutes,
          loading_dias: false,
          dias: state.listRoutes.dias,
          diario: state.listRoutes.diario,
          loading_routes: true,
          routes: [],
          acum: state.listRoutes.acum,
          stats: state.listRoutes.stats,
        },
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.GET_GEOFENCES:
      return {
        ...state,
        geofences: { ...state.geofences, loading: true },
      };
    case actions.UPDATE_GEOFENCE:
    case actions.UPDATE_GEOFENCE_NAME:
      return {
        ...state,
        geofences: { ...state.geofences, updating: true },
      };
    case actions.CREATE_CENTRO:
      return {
        ...state,
        centros: { ...state.centros, creating: true },
      };
    case actions.DEVICES_RECEIVED:
      const clientes = getClientesFromDevices(action.data);
      if (state.auxDevices.loading) {
        return {
          ...state,
          devices: action.data,
          loading: false,
          lastChange: {},
          subscription: 0,
          last_imei_subscription: '',
          clientes: clientes,
          numeroClientes: clientes.length,
          auxDevices: action.data,
          softLoadDevices: action.data,
        };
      } else {
        return {
          ...state,
          devices: action.data,
          loading: false,
          lastChange: {},
          subscription: 0,
          last_imei_subscription: '',
          clientes: clientes,
          numeroClientes: clientes.length,
          softLoadDevices: action.data,
        };
      }
    case actions.ACUMS_RECEIVED:
      return {
        ...state,
        statsAcums: action.data,
        loadingAcums: false,
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.DIAS_RECEIVED:
      return {
        ...state,
        //        listRoutes:{loading_dias:false,dias:action.data.dias,diario:action.data.diario,loading_routes:false,routes:[]},
        listRoutes: {
          ...state.listRoutes,
          loading_dias: false,
          dias: action.data.dias,
          diario: action.data.diario,
          acum: action.data.acum,
          stats: action.data.stats,
        },
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.DIAS_RECEIVED_NOTIS:
      return {
        ...state,
        listRoutesNotis: {
          ...state.listRoutesNotis,
          loading_dias: false,
          dias: action.data.dias,
          diario: action.data.diario,
          acum: action.data.acum,
          stats: action.data.stats,
        },
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.DIAS_RECEIVED_GEOS:
      return {
        ...state,
        listRoutesGeos: {
          ...state.listRoutesGeos,
          loading_dias: false,
          dias: action.data.dias,
          diario: action.data.diario,
          acum: action.data.acum,
          stats: action.data.stats,
        },
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.DIAS_RECEIVED_FUEL:
      return {
        ...state,
        listRoutesFuel: {
          ...state.listRoutesFuel,
          loading_dias: false,
          dias: action.data.dias,
          diario: action.data.diario,
          acum: action.data.acum,
          stats: action.data.stats,
        },
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.DIAS_RECEIVED_STATS:
      return {
        ...state,
        listRoutesStats: {
          ...state.listRoutesStats,
          loading_dias: false,
          dias: action.data.dias,
          diario: action.data.diario,
          acum: action.data.acum,
          stats: action.data.stats,
        },
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.DIAS_RECEIVED_RIDERS:
      return {
        ...state,
        listRoutesRiders: {
          ...state.listRoutesRiders,
          loading_dias: false,
          dias: action.data.dias,
          diario: action.data.diario,
          acum: action.data.acum,
          stats: action.data.stats,
        },
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.DIAS_RECEIVED_ROUTETIME:
      return {
        ...state,
        listRoutesRouteTime: {
          ...state.listRoutesRouteTime,
          loading_dias: false,
          dias: action.data.dias,
          diario: action.data.diario,
          acum: action.data.acum,
          stats: action.data.stats,
        },
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.DIAS_RECEIVED_CUSTOM:
      return {
        ...state,
        //        listRoutes:{loading_dias:false,dias:action.data.dias,diario:action.data.diario,loading_routes:false,routes:[]},
        listRoutesCustom: {
          ...state.listRoutesCustom,
          loading_dias: false,
          dias: action.data.dias,
          diario: action.data.diario,
          acum: action.data.acum,
          stats: action.data.stats,
        },
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.TODAY_KMS_RECEIVED:
      const currentKms =
        'currentKms' in action.data ? action.data.currentKms : 0;
      const currentRoutes =
        'currentRoutes' in action.data ? action.data.currentRoutes : 0;
      const currentTime =
        'currentTime' in action.data ? action.data.currentTime : 0;
      const currentMaxSpeed =
        'currentMaxSpeed' in action.data ? action.data.currentMaxSpeed : 0;
      //console.log("data kms received", action.data);
      return {
        ...state,
        kmsToday: {
          loading: false,
          datos: [
            {
              number: Math.round(currentKms),
              text: 'widget.stickerwidget1.text',
              icon: 'road',
              fontColor: '#ffffff',
              bgColor: '#7266BA',
              loading: false,
            },
            {
              number: currentRoutes,
              text: 'widget.stickerwidget2.text',
              icon: 'route',
              fontColor: '#ffffff',
              bgColor: '#42A5F6',
              loading: false,
            },
            {
              number: getTimeFormat(currentTime, 'hm'),
              seconds: currentTime,
              text: 'widget.stickerwidget3.text',
              icon: 'clock',
              fontColor: '#ffffff',
              bgColor: '#7ED320',
              loading: false,
            },
            {
              number: Math.round(currentMaxSpeed),
              text: 'widget.stickerwidget4.text',
              icon: 'gauge-max',
              fontColor: '#ffffff',
              bgColor: '#F75D81',
              loading: false,
            },
          ],
        },
      };
    case actions.GEOFENCES_RECEIVED:
      let geofencesArray = [];
      Object.keys(action.data).forEach(geofence => {
        geofencesArray.push(action.data[geofence]);
      });
      return {
        ...state,
        geofences: {
          list: geofencesArray,
          loading: false,
          updating: false,
        },
      };
    case actions.GEOFENCES_EVENTS_RECEIVED:
      return {
        ...state,
        geofencesEvents: {
          list: action.data,
          loading: false,
        },
      };
    case actions.DEALERS_RECEIVED:
      return {
        ...state,
        dealers: {
          list: action.data,
          loading: false,
        },
      };
    case actions.GET_DEALERS_FAILED:
      return {
        ...state,
        dealers: {
          list: [],
          loading: false,
        },
      };
    case actions.CENTROS_RECEIVED:
      return {
        ...state,
        centros: {
          list: action.data,
          loading: false,
          creating: false,
          updating: false,
        },
      };
    case actions.RIDERS_RECEIVED:
      return {
        ...state,
        conductores: { list: action.data, loading: true },
      };
    case actions.GET_NOTIF_GRAFICAS:
      return {
        ...state,
        notificationsGraficas: { ...action.data, loading: false },
      };
    case actions.ROUTES_RECEIVED:
      /*
      var aux=0;
      if (action.data.length > 0) {
        if (state.devices[action.data[0].data.imei]) {
           aux = state.devices[action.data[0].data.imei].max_speed;
        }
      }
      */
      return {
        ...state,
        //listRoutes:{loading_dias:state.listRoutes.loading_dias,dias:state.listRoutes.dias,diario:state.listRoutes.diario,loading_ro
        listRoutes: {
          ...state.listRoutes,
          loading_routes: false,
          routes: action.data,
        },
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.FETCH_FAILED:
      return {
        ...state,
        //devices:{},
        loading: false,
        lastChange: {},
        subscription: 0,
        last_imei_subscription: '',
      };
    case actions.ADDRESS_MAPBOX_RECEIVED:
      var calleini =
        action.data[0].features.length > 0
          ? action.data[0].features[0].place_name
          : '';
      var callefin =
        action.data[1].features.length > 0
          ? action.data[1].features[0].place_name
          : '';
      var aux = {
        calleInicio: calleini,
        calleFin: callefin,
        kms: action.data[2],
        horaInicio: action.data[3],
        horaFin: action.data[4],
        tiempo: action.data[5],
        dia: action.data[6],
        matricula: action.data[7],
        diaCorto: action.data[8],
        difAlt: action.data[9],
      };
      return {
        ...state,
        rutaInfo: aux,
      };
    case actions.ALERT_RECEIVED:
      let auxNotifs = [...state.notifications.list];
      let auxAlarms = [...state.currentAlarms.list];
      let auxAlarmsTop = [...state.topbarAlarms.list];
      let countNotifs = state.notifications.count;
      let auxLastAlarm = {};
      //console.info("ACTION DATA QUIERO PARAOOOOOOOOOOOOOOOOOOOOO", action.data)
      //alert("ACTION DATA QUIERO PARAOOOOOOOOOOOOOOOOOOOOO", action.data)
      /*if (
        state.notifications.list.length === 0 &&
        (state.notifications.list.filter(n => n.entity === action.data.code)
          .length > 0 ||
          state.notifications.noFilter)true
      ) {
        auxNotifs.unshift({
          /*address:
            action.data.address && action.data.address[0]
              ? action.data.address[0]
              : '',/////*
          addressformated:
            action.data.address && action.data.address[0]
              ? action.data.address[0].formattedAddress
              : '',
          centerid: action.data.centertoken,
          centername: action.data.data.center,
          center: action.data.data.center,
          customer_id: action.data.customer_id,
          customer_name: action.data.data.name,
          dealerid: action.data.dealerid,
          dealername: action.data.data.dealername,
          date: action.data.data.date,
          entity: action.data.code,
          entity_name: action.data.title,
          _id: action.data.data.date,
          numberplate: action.data.data.matricula,
          riderid: action.data.data.riderid,
          ridername: action.data.data.ridername,
          subject: action.data.imei,
          template: '',
          title: action.data.title,
          type: action.data.type,
        });
        //++countNotifs;
       // console.info("NUEVA NOTIF", auxNotifs, state.notifications.list, action.data)
        //if (auxNotifs.length > 10) auxNotifs.pop();
      }*/
      if (
        action.data.type.toUpperCase() === 'ALARM' ||
        action.data.type.toUpperCase() === 'WARN'
      ) {
        auxAlarms.unshift({
          codex: action.data.code,
          date: action.data.data.last_data,
          imei: action.data.imei,
          matricula: action.data.data.matricula,
          text: action.data.title,
          type: action.data.type.toUpperCase(),
        });
        auxAlarmsTop.unshift({
          codex: action.data.code,
          date: action.data.data.last_data,
          imei: action.data.imei,
          matricula: action.data.data.matricula,
          text: action.data.title,
          type: action.data.type.toUpperCase(),
        });
        auxLastAlarm = {
          codex: action.data.code,
          date: action.data.data.last_data,
          imei: action.data.imei,
          matricula: action.data.data.matricula,
          text: action.data.title,
          type: action.data.type.toUpperCase(),
        };
      }
      return {
        ...state,
        //notifications: { list: auxNotifs, count: countNotifs, loading: false },
        currentAlarms: { list: auxAlarms, last: auxLastAlarm, loading: false },
        topbarAlarms: {
          list: auxAlarmsTop,
          last: auxLastAlarm,
          loading: false,
        },
      };
    case actions.CURRENT_ALARMS_RECEIVED:
      if (state.topbarAlarms.list.length === 0) {
        return {
          ...state,
          currentAlarms: { list: [...action.data], last: {}, loading: false },
          topbarAlarms: { list: [...action.data], last: {}, loading: false },
        };
      } else {
        return {
          ...state,
          currentAlarms: { list: [...action.data], last: {}, loading: false },
        };
      }
    case actions.SCAN_QR:
      //console.info("action", state.devices, action.data, Object.values(state.devices).filter(e => e.matricula === "9127LNK")); //
      if (Object.values(state.devices).some(e => e.veh_token === action.data)) {
        return {
          ...state,
          imeiQR: Object.values(state.devices).filter(
            e => e.veh_token === action.data
          )[0].imei,
        };
      } else {
        return {
          ...state,
          imeiQR: 'No se ha encontrado el vehículo.',
        };
      }
    case actions.CLEAR_QR:
      return {
        ...state,
        imeiQR: '',
      };
    case actions.REMOVE_ALERT_SUCCESS:
      //console.info("ACTION DATA REMOVE SUCC", action.data, state.currentAlarms.list.filter(e => e.date !== action.data.date || e.matricula !== action.data.matricula), state.currentAlarms.list)
      return {
        ...state,
        currentAlarms: {
          ...state.currentAlarms,
          list: state.currentAlarms.list.filter(
            e =>
              e.date !== action.data.date ||
              e.matricula !== action.data.matricula
          ),
        },
      };
    case actions.TRACKER_RECEIVED:
      var objw = {},
        velocidadAnt = 0,
        velocidadAct = 0; //,tiempoAnt = 0,tiempoAct = 0;
      if (
        state.devices.hasOwnProperty(action.data.imei) &&
        state.devices[action.data.imei].last_time < action.data.time
      ) {
        //if(state.devices[action.data.imei].matricula === "FLOTA30")console.log("state", state.devices[action.data.imei], state.devices[action.data.imei].locked, action.data.locked, action.data)
        objw = state.devices[action.data.imei];
        /*
        state.devices[action.data.imei].last_speed = action.data.speed_cal;
        state.devices[action.data.imei].last_data = action.data.date;
        state.devices[action.data.imei].last_latitude = action.data.latitude_cal;
        state.devices[action.data.imei].last_longitude = action.data.longitude_cal;
        if (action.data.odometer) state.devices[action.data.imei].odometer = action.data.odometer;
        if (action.data.socket_id) state.devices[action.data.imei].online = 'processing'; else state.devices[action.data.imei].online = 'default';  
        */
        //console.log("tracker", action.data.online, action.data.locked)
        objw.last_speed = action.data.speed_cal;
        objw.last_data = action.data.date;
        objw.last_latitude = action.data.latitude_cal;
        objw.last_longitude = action.data.longitude_cal;
        objw.direction = action.data.direction;
        //tiempoAnt = state.devices[action.data.imei].last_time;
        objw.last_time = action.data.time;
        //console.log("socket??", action.data)
        objw.status = action.data.status;
        objw.online = action.data.online;
        if (action.data.online === 'processing') objw.online = false;
        //tiempoAct = action.data.time;
        if (action.data.odometer) {
          velocidadAnt = state.devices[action.data.imei].odometer;
          objw.odometer = action.data.odometer;
          velocidadAct = objw.odometer;
        }
        //if (action.data.socket_id)  'processing'; else objw.online = 'default';
        //  state.devices = {...state.devices,[action.data.imei]:objw};
        //console.log("maxSpeed", action.data);
        let sumaKmsHoy = Math.round(
          velocidadAct - velocidadAnt + state.kmsToday.datos[0].number
        );
        //let sumaTiempoHoy = (parseInt(tiempoAct) - parseInt(tiempoAnt)) + state.kmsToday.datos[2].seconds;
        // console.log("kms today", state.kmsToday.datos[0].number)
        let datosAux = { ...state.kmsToday };
        if (action.data.speed_cal > state.kmsToday.datos[3].number)
          datosAux.datos[3].number = action.data.speed_cal;
        if (velocidadAnt >= 0 && velocidadAct >= 0 && sumaKmsHoy > 0) {
          datosAux.datos[0].number = sumaKmsHoy;
        }
        //console.log("kms today", action.data, sumaTiempoHoy, parseInt(tiempoAct), parseInt(tiempoAct));

        /*                 Calculo del tiempo diario, no estoy seguro de que esto funcione correctamente                                */
        //datosAux.datos[2].seconds = sumaTiempoHoy;
        //datosAux.datos[2].number = getTimeFormat(sumaTiempoHoy, 'hm');

        //Esto está para cargar el objeto de vehiculos con carga lenta a parte, cada segundo
        let duration = moment.duration(
          moment(new Date()).diff(state.currentTrackerDate)
        );
        var seconds = duration.asSeconds();

        if (seconds > 1 || state.softLoadDevices.loading) {
          return {
            ...state,
            lastChange: action.data,
            subscription: 1,
            devices: { ...state.devices, [action.data.imei]: { ...objw } },
            softLoadDevices: {
              ...state.devices,
              [action.data.imei]: { ...objw },
            },
            kmsToday: { ...datosAux },
            last_imei_subscription: action.data.imei,
            currentTrackerDate: moment(new Date()),
          };
        } else {
          return {
            ...state,
            lastChange: action.data,
            subscription: 1,
            devices: { ...state.devices, [action.data.imei]: { ...objw } },
            kmsToday: { ...datosAux },
            last_imei_subscription: action.data.imei,
          };
        }
      } else {
        return state;
      }
    case actions.GET_CONFIG_NOTIF:
      return {
        ...state,
        configNotifications: [],
        configNotificationsLoading: true,
      };
    case actions.GET_CONFIG_NOTIF_RECEIVED:
      return {
        ...state,
        configNotifications: action.data,
        configNotificationsLoading: false,
      };
    case actions.GET_PROFILE_HTTP_SUCCESS:
      return {
        ...state,
        configParamsHttp: action.data,
      };
    case actions.GET_TALLERES:
      return {
        ...state,
        talleres: { loading: true, list: [] },
      };
    case actions.GET_TALLERES_SUCCESS:
      return {
        ...state,
        talleres: { loading: false, list: action.data },
      };
    case actions.GET_MAPA_ALERTA:
      return {
        ...state,
        mapAlertImage: null,
      };
    case actions.GET_MAPA_ALERTA_SUCCESS:
      return {
        ...state,
        mapAlertImage: action.data,
      };
    case actions.GET_ROUTES_RIDERS_SUCCESS:
      return {
        ...state,
        routesRider: {
          routes: [...action.data],
          loading: false,
        },
      };
    case actions.GET_GEOFENCES_OBJECTS_SUCCESS:
      return {
        ...state,
        geofencesObject: {
          list: [...action.data],
          loading: false,
        },
      };
    default:
      return state;
  }
}
