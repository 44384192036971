const actions = {
  SET_ONLINE: 'SET_ONLINE',
  GET_DEVICES: 'GET_DEVICES',
  GET_ROUTES: 'GET_ROUTES',
  GET_GEOFENCES: 'GET_GEOFENCES',
  GET_DIAS: 'GET_DIAS',
  GET_DIAS_MONTHLY: 'GET_DIAS_MONTHLY',
  GET_ACUMS: 'GET_ACUMS',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  GET_ALARMS: 'GET_ALARMS',
  GET_CURRENT_ALARMS: 'GET_CURRENT_ALARMS',
  GET_NOTIF_TYPES: 'GET_NOTIF_TYPES',
  GET_CENTROS: 'GET_CENTROS',
  GET_TODAY_KMS: 'GET_TODAY_KMS',
  GET_DEALERS: 'GET_DEALERS',
  SET_DEALERS_VEH: 'SET_DEALERS_VEH',
  SET_DEALERS_RID: 'SET_DEALERS_RID',
  UPDATE_DEALER: 'UPDATE_DEALER',

  DEVICES_RECEIVED: 'DEVICES_RECEIVED',
  ROUTES_RECEIVED: 'ROUTES_RECEIVED',
  GEOFENCES_RECEIVED: 'GEOFENCES_RECEIVED',
  NOTIFICATIONS_RECEIVED: 'NOTIFICATIONS_RECEIVED',
  NOTIFICATIONS_CLI_RECEIVED: 'NOTIFICATIONS_CLI_RECEIVED',
  ALARMS_RECEIVED: 'ALARMS_RECEIVED',
  CURRENT_ALARMS_RECEIVED: 'CURRENT_ALARMS_RECEIVED',
  NOTIF_TYPES_RECEIVED: 'NOTIF_TYPES_RECEIVED',
  CENTROS_RECEIVED: 'CENTROS_RECEIVED',
  TODAY_KMS_RECEIVED: 'TODAY_KMS_RECEIVED',
  RIDERS_RECEIVED: 'RIDERS_RECEIVED',
  ACUMS_RECEIVED: 'ACUMS_RECEIVED',
  DEALERS_RECEIVED: 'DEALERS_RECEIVED',
  GET_DEALERS_FAILED: 'GET_DEALERS_FAILED',

  FETCH_FAILED: 'FETCH_FAILED',
  TRACKER_RECEIVED: 'TRACKER_RECEIVED',
  ALERT_RECEIVED: 'ALERT_RECEIVED',
  DIAS_RECEIVED: 'DIAS_RECEIVED',
  DIAS_MONTHLY_RECEIVED: 'DIAS_MONTHLY_RECEIVED',
  DIAS_RECEIVED_NOTIS: 'DIAS_RECEIVED_NOTIS',
  DIAS_RECEIVED_GEOS: 'DIAS_RECEIVED_GEOS',
  DIAS_RECEIVED_FUEL: 'DIAS_RECEIVED_FUEL',
  DIAS_RECEIVED_STATS: 'DIAS_RECEIVED_STATS',
  DIAS_RECEIVED_RIDERS: 'DIAS_RECEIVED_RIDERS',
  DIAS_RECEIVED_ROUTETIME: 'DIAS_RECEIVED_ROUTETIME',
  DIAS_RECEIVED_CUSTOM: 'DIAS_RECEIVED_CUSTOM',
  SET_NEGOCIO: 'SET_NEGOCIO',
  SET_CLIENTE: 'SET_CLIENTE',
  SET_VEHTYPE: 'SET_VEHTYPE',
  SET_DIARIO_FILTER_DIA: 'SET_DIARIO_FILTER_DIA',
  SET_DASHBOARD_VISIBLE: 'SET_DASHBOARD_VISIBLE',

  ADD_DEVICES: 'ADD_DEVICES',
  SET_RIDERS_CENTER: 'SET_RIDERS_CENTER',
  SET_RIDER_VEHICLE: 'SET_RIDER_VEHICLE',
  GET_GEOFENCES_EVENTS: 'GET_GEOFENCES_EVENTS',
  GEOFENCES_EVENTS_RECEIVED: 'GEOFENCES_EVENTS_RECEIVED',
  UPDATE_GEOFENCE: 'UPDATE_GEOFENCE',
  UPDATE_GEOFENCE_NAME: 'UPDATE_GEOFENCE_NAME',
  CREATE_GEOFENCE: 'CREATE_GEOFENCE',
  UPDATE_CENTRO_GEOFENCE: 'UPDATE_CENTRO_GEOFENCE',
  UPDATE_RIDER: 'UPDATE_RIDER',
  UPDATE_CENTRO: 'UPDATE_CENTRO',
  CREATE_CENTRO: 'CREATE_CENTRO',
  CENTRO_CREATED: 'CENTRO_CREATED',

  GET_ADDRESS_MAPBOX: 'GET_ADDRESS_MAPBOX',
  ADDRESS_MAPBOX_RECEIVED: 'ADDRESS_MAPBOX_RECEIVED',

  GET_NOTIF_GRAFICAS: 'GET_NOTIF_GRAFICAS',

  REMOVE_ALERT: 'REMOVE_ALERT',
  REMOVE_ALERT_SUCCESS: 'REMOVE_ALERT_SUCCESS',

  SCAN_QR: 'SCAN_QR',
  CLEAR_QR: 'CLEAR_QR',

  //RIDERS
  GET_RIDERS: 'GET_RIDERS',
  GET_ROUTES_RIDERS: 'GET_ROUTES_RIDERS',
  GET_ROUTES_RIDERS_SUCCESS: 'GET_ROUTES_RIDERS_SUCCESS',

  //CONFIGURACION NOTIFICACIONES
  SET_CONFIG_NOTIF: 'SET_CONFIG_NOTIF',
  GET_CONFIG_NOTIF: 'GET_CONFIG_NOTIF',
  GET_CONFIG_NOTIF_RECEIVED: 'GET_CONFIG_NOTIF_RECEIVED',

  //PROFILE
  SET_PROFILE: 'SET_PROFILE',
  SET_PROFILE_HTTP: 'SET_PROFILE_HTTP',
  GET_PROFILE_HTTP: 'GET_PROFILE_HTTP',
  GET_PROFILE_HTTP_SUCCESS: 'GET_PROFILE_HTTP_SUCCESS',

  //TALLERES
  GET_TALLERES: 'GET_TALLERES',
  GET_TALLERES_SUCCESS: 'GET_TALLERES_SUCCESS',

  //IMAGEN MAPA
  GET_MAPA_ALERTA: 'GET_MAPA_ALERTA',
  GET_MAPA_ALERTA_SUCCESS: 'GET_MAPA_ALERTA_SUCCESS',

  //GEOF OBJECT
  GET_GEOFENCES_OBJECTS: 'GET_GEOFENCES_OBJECTS',
  GET_GEOFENCES_OBJECTS_SUCCESS: 'GET_GEOFENCES_OBJECTS_SUCCESS',

  //BLOQ/DESBQ VEH
  SET_BLQ_VEH: 'SET_BLQ_VEH',

  setOnline: data => ({
    type: actions.SET_ONLINE,
    data,
  }),
  getDevices: cambioClientes => ({
    type: actions.GET_DEVICES,
    cambioClientes,
  }),
  addDevices: args => ({
    type: actions.ADD_DEVICES,
    args,
  }),
  getRiders: args => ({
    type: actions.GET_RIDERS,
    args,
  }),
  getRoutes: (i_imei, i_day, matricula) => ({
    type: actions.GET_ROUTES,
    i_imei,
    i_day,
    matricula,
  }),
  getRoutesRiders: args => ({
    type: actions.GET_ROUTES_RIDERS,
    args,
  }),
  getRoutesRidersSuccess: data => ({
    type: actions.GET_ROUTES_RIDERS_SUCCESS,
    data,
  }),
  getCentros: args => ({
    type: actions.GET_CENTROS,
    args,
  }),
  updateCentroGeofence: args => ({
    type: actions.UPDATE_CENTRO_GEOFENCE,
    args,
  }),
  updateRider: args => ({
    type: actions.UPDATE_RIDER,
    args,
  }),
  updateCentro: args => ({
    type: actions.UPDATE_CENTRO,
    args,
  }),
  getGeofences: args => ({
    type: actions.GET_GEOFENCES,
    args,
  }),
  createGeofence: args => ({
    type: actions.CREATE_GEOFENCE,
    args,
  }),
  updateGeofence: args => ({
    type: actions.UPDATE_GEOFENCE,
    args,
  }),
  updateGeofenceName: args => ({
    type: actions.UPDATE_GEOFENCE_NAME,
    args,
  }),
  createCentro: args => ({
    type: actions.CREATE_CENTRO,
    args,
  }),
  getTodayKms: args => ({
    type: actions.GET_TODAY_KMS,
    args,
  }),
  getDias: args => ({
    type: actions.GET_DIAS,
    args,
  }),
  getDiasMonthly: args => ({
    type: actions.GET_DIAS_MONTHLY,
    args,
  }),
  getAcums: args => ({
    type: actions.GET_ACUMS,
    args,
  }),
  getNotifications: args => ({
    type: actions.GET_NOTIFICATIONS,
    args,
  }),
  getAlarms: args => ({
    type: actions.GET_ALARMS,
    args,
  }),
  getNotifsTypes: args => ({
    type: actions.GET_NOTIF_TYPES,
    args,
  }),
  getCurrentAlarms: (args, topbar) => ({
    type: actions.GET_CURRENT_ALARMS,
    args,
    topbar,
  }),
  setRiderVehicle: args => ({
    type: actions.SET_RIDER_VEHICLE,
    args,
  }),
  setRidersCenter: args => ({
    type: actions.SET_RIDERS_CENTER,
    args,
  }),
  setSubscriptionTracker: data => ({
    type: actions.TRACKER_RECEIVED,
    data,
  }),
  setSubscriptionAlert: data => ({
    type: actions.ALERT_RECEIVED,
    data,
  }),
  setNegocio: data => ({
    type: actions.SET_NEGOCIO,
    data,
  }),
  setCliente: data => ({
    type: actions.SET_CLIENTE,
    data,
  }),
  setVehType: data => ({
    type: actions.SET_VEHTYPE,
    data,
  }),
  setDiarioFilterDia: data => ({
    type: actions.SET_DIARIO_FILTER_DIA,
    data,
  }),
  setDashboardVisible: data => ({
    type: actions.SET_DASHBOARD_VISIBLE,
    data,
  }),
  removeAlert: data => ({
    type: actions.REMOVE_ALERT,
    data,
  }),
  removeAlertSuccess: data => ({
    type: actions.REMOVE_ALERT_SUCCESS,
    data,
  }),
  getNotifGraficas: args => ({
    type: actions.GET_NOTIF_GRAFICAS,
    args,
  }),
  getAddressMapbox: data => ({
    type: actions.GET_ADDRESS_MAPBOX,
    data,
  }),
  getGeofencesEvents: args => ({
    type: actions.GET_GEOFENCES_EVENTS,
    args,
  }),
  scanQR: data => ({
    type: actions.SCAN_QR,
    data,
  }),
  clearQR: data => ({
    type: actions.CLEAR_QR,
  }),
  getDealers: data => ({
    type: actions.GET_DEALERS,
    data,
  }),
  getDealersFailed: data => ({
    type: actions.GET_DEALERS_FAILED,
    data,
  }),
  setDeviceDealer: data => ({
    type: actions.SET_DEALERS_VEH,
    data,
  }),
  setRiderDealer: data => ({
    type: actions.SET_DEALERS_RID,
    data,
  }),
  updateDealer: data => ({
    type: actions.UPDATE_DEALER,
    data,
  }),
  setConfigNotif: data => ({
    type: actions.SET_CONFIG_NOTIF,
    data,
  }),
  getConfigNotif: data => ({
    type: actions.GET_CONFIG_NOTIF,
    data,
  }),
  setProfile: data => ({
    type: actions.SET_PROFILE,
    data,
  }),
  setProfileHTTP: data => ({
    type: actions.SET_PROFILE_HTTP,
    data,
  }),
  getProfileHTTP: args => ({
    type: actions.GET_PROFILE_HTTP,
    args,
  }),
  getProfileHTTPSuccess: data => ({
    type: actions.GET_PROFILE_HTTP_SUCCESS,
    data,
  }),
  getTalleres: args => ({
    type: actions.GET_TALLERES,
    args,
  }),
  getTalleresSuccess: data => ({
    type: actions.GET_TALLERES_SUCCESS,
    data,
  }),
  getMapaAlerta: args => ({
    type: actions.GET_MAPA_ALERTA,
    args,
  }),
  getMapaAlertaSuccess: data => ({
    type: actions.GET_MAPA_ALERTA_SUCCESS,
    data,
  }),
  getGeofencesObjects: args => ({
    type: actions.GET_GEOFENCES_OBJECTS,
    args,
  }),
  getGeofencesObjectsSuccess: data => ({
    type: actions.GET_GEOFENCES_OBJECTS_SUCCESS,
    data,
  }),
  setBlqVeh: args => ({
    type: actions.SET_BLQ_VEH,
    args,
  }),
};

export default actions;
