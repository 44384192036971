// Notabilus saga
/*David 8-4-21*/
import {
  all,
  call,
  put,
  select,
  takeLatest,
  delay,
  /*takeEvery*/
} from 'redux-saga/effects';
import actions from './actions';
import siteConfig from '@iso/config/site.config';
//import { select } from 'redux-saga';
import { notification } from 'antd';

export const getProject = state => state.NotaBilus;

const openNotification = (type, mes, desc) => {
  notification[type]({
    message: mes,
    description: desc,
  });
};

function getFilters(sourcew) {
  let filterw = {};
  if (sourcew.filterNegocio) {
    filterw.source = sourcew.filterNegocio;
  }
  if (sourcew.filterCliente.customer_id) {
    filterw.customer_id = sourcew.filterCliente.customer_id;
  }
  /*if(localStorage.session){
    filterw.session = localStorage.session
  }*/
  /*if(sourcew.filterVehiculo){
    filterw.
  }*/
  return filterw;
}

function* fetchDevices() {
  try {
    let filterw = getFilters(yield select(getProject));
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getDevicesObj',
        filter: filterw,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    yield put({ type: actions.DEVICES_RECEIVED, data: data });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* addDevicesSaga(args) {
  try {
    //console.log("ARGS", args.args);
    /*const res = */ yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'setDevicesCenter',
        filter: args.args,
        session: localStorage.session,
      }),
    });
    yield put({ type: actions.GET_CENTROS, args: args.args });
    yield delay(1000);
    yield put({ type: actions.GET_DEVICES });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* setDeviceDealerSaga(data) {
  try {
    let filterw = getFilters(yield select(getProject));
    if (data.data.action && data.data.action !== '') {
      filterw.action = data.data.action;
    }
    if (data.data.dealerid && data.data.dealerid !== '') {
      filterw.dealerid = data.data.dealerid;
    }
    if (data.data.imeis && data.data.imeis !== '') {
      filterw.imeis = data.data.imeis;
    }
    if (data.data.customer_id && data.data.customer_id !== '') {
      filterw.customer_id = data.data.customer_id;
    }

    yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'setDevicesDealer',
        filter: filterw,
        session: localStorage.session,
      }),
    });

    //yield put({ type: actions.GET_DEALERS });
    yield delay(1000);
    yield put({ type: actions.GET_DEVICES });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* setRiderDealerSaga(data) {
  try {
    let filterw = getFilters(yield select(getProject));
    if (data.data.action && data.data.action !== '') {
      filterw.action = data.data.action;
    }
    if (data.data.dealerid && data.data.dealerid !== '') {
      filterw.dealerid = data.data.dealerid;
    }
    if (data.data.riders && data.data.riders !== '') {
      filterw.riders = data.data.riders;
    }
    if (data.data.customer_id && data.data.customer_id !== '') {
      filterw.customer_id = data.data.customer_id;
    }

    //console.info("toma saga", data, "FILTER: ", filterw)

    yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'setRidersDealer',
        filter: filterw,
        session: localStorage.session,
      }),
    });

    //yield put({ type: actions.GET_DEALERS });
    yield delay(1000);
    yield put({ type: actions.GET_RIDERS });
    yield put({ type: actions.GET_DEVICES });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* updateDealerSaga(data) {
  try {
    let act = data.data.action;
    if (data.data.action) delete data.data.action;
    yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'actDealers',
        action: act,
        filter: data.data,
        session: localStorage.session,
      }),
    });
    //console.info("saga saguita", data)
    yield put({ type: actions.GET_DEALERS });
    yield put({ type: actions.GET_DEVICES });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* fetchRoutes(args) {
  try {
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getRoutesMongo',
        imei: args.i_imei,
        matricula: args.matricula,
        dfec: new Date(args.i_day),
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    if (data.length > 0) data[0].data.imei = args.i_imei;
    yield put({ type: actions.ROUTES_RECEIVED, data: data });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* fetchRoutesRiders(args) {
  try {
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getRoutesMongo',
        rider: args.args.rider,
        dfec: args.args.dfec,
        hfec: args.args.hfec,
        start: args.args.start,
        rows: args.args.rows,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    yield put({ type: actions.GET_ROUTES_RIDERS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* fetchGeofences(args) {
  try {
    let filterw = getFilters(yield select(getProject));
    /*if(Object.keys(filterw).length === 0){
        console.log(1);
        if(args){
            console.log(args);
            if(args.args.customer_id){
                console.log(3);
                filterw.customer_id = args.args.customer_id;
            }
            if(args.args.dealerid){
                console.log(4);
                filterw.dealerid = args.args.dealerid;
            }
        }
    }*/
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getGeoFences',
        filter: filterw,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    yield put({ type: actions.GEOFENCES_RECEIVED, data: data });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* fetchAcums(args) {
  try {
    let aux = yield select(getProject);
    let sourcew = '',
      namew = '',
      customer_idw = '';
    if (aux.filterNegocio) {
      sourcew = aux.filterNegocio;
    }
    if (aux.filterCliente.name) {
      namew = aux.filterCliente.name;
    }
    //console.info("aux", aux.filterCliente)
    if (aux.filterCliente.customer_id) {
      customer_idw = aux.filterCliente.customer_id;
    }
    //console.log("aux ",aux);
    //console.log("args ",args);
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getAcums',
        filter: {
          imei: args.args.imei,
          numberplate: args.args.matricula,
          rentid: args.args.rentid,
        },
        format: 'YYYY-MM-DD',
        dfec: args.args.dfec,
        hfec: args.args.hfec,
        gap: args.args.gap,
        source: args.args.source ? args.args.source : sourcew,
        customer_id: args.args.customer_id
          ? args.args.customer_id
          : customer_idw,
        dealerid: args.args.dealerid ? args.args.dealerid : '',
        name: namew,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    yield put({ type: actions.ACUMS_RECEIVED, data: data });
  } catch (e) {
    console.info('fallo acums', e.message);
  }
}

function* fetchDias(args) {
  try {
    let aux = yield select(getProject);
    let sourcew = '',
      namew = '',
      custom = false,
      tipo = '';
    if (aux.filterNegocio) {
      sourcew = aux.filterNegocio;
    }
    if (aux.filterCliente.name) {
      namew = aux.filterCliente.name;
    }
    if (args.args.custom) {
      custom = args.args.custom;
    }
    if (args.args.tipo) {
      tipo = args.args.tipo;
    }
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'dias',
        imei: args.args.imei,
        matricula: args.args.matricula,
        format: 'YYYY-MM-DD',
        dfec: args.args.dfec,
        hfec: args.args.hfec,
        source: sourcew,
        name: namew,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    if (custom === true) {
      yield put({ type: actions.DIAS_RECEIVED_CUSTOM, data: data });
    } else {
      switch (
        tipo //Para meter estos datos en el objeto a parte en la ficha del vehículo
      ) {
        case 'NOTIS':
          yield put({ type: actions.DIAS_RECEIVED_NOTIS, data: data });
          break;
        case 'GEOS':
          yield put({ type: actions.DIAS_RECEIVED_GEOS, data: data });
          break;
        case 'FUEL':
          yield put({ type: actions.DIAS_RECEIVED_FUEL, data: data });
          break;
        case 'STATS':
          yield put({ type: actions.DIAS_RECEIVED_STATS, data: data });
          break;
        case 'RIDERS':
          yield put({ type: actions.DIAS_RECEIVED_RIDERS, data: data });
          break;
        case 'ROUTETIME':
          yield put({ type: actions.DIAS_RECEIVED_ROUTETIME, data: data });
          break;
        default:
          yield put({ type: actions.DIAS_RECEIVED, data: data });
          break;
      }
    }
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* fetchDiasMonthly(args) {
  try {
    let aux = yield select(getProject);
    let sourcew = '',
      namew = '',
      custom = false,
      tipo = '';
    if (aux.filterNegocio) {
      sourcew = aux.filterNegocio;
    }
    if (aux.filterCliente.name) {
      namew = aux.filterCliente.name;
    }
    if (args.args.custom) {
      custom = args.args.custom;
    }
    if (args.args.tipo) {
      tipo = args.args.tipo;
    }
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'dias',
        imei: args.args.imei,
        matricula: args.args.matricula,
        format: 'YYYY-MM-DD',
        dfec: args.args.dfec,
        hfec: args.args.hfec,
        source: sourcew,
        name: namew,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    yield put({ type: actions.DIAS_MONTHLY_RECEIVED, data: data });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* fetchNotifications(args) {
  try {
    //console.log("ARGS ",args);
    let im = '',
      slf = 0,
      start = 0,
      matricula = '';
    let filterw = getFilters(yield select(getProject));
    let noFilter = false;
    filterw.rows = 10;

    //console.log("filters ",filterw);
    if (args) {
      im = args.args.i_imei;
      slf = args.args.self;
      start = args.args.start;
      matricula = args.args.matricula;
      if (args.args.codex) {
        filterw.codex = args.args.codex;
      } else noFilter = true;
      if (args.args.rows) {
        filterw.rows = args.args.rows;
      }
      if (args.args.geofence) {
        filterw.geofence = args.args.geofence;
      }
      if (args.args.codex) {
        filterw.codex = args.args.codex;
      }
      if (args.args.rider) {
        filterw.rider = args.args.rider;
      }
      if (args.args.hfec) {
        filterw.hfec = args.args.hfec;
      }
      if (args.args.dfec) {
        filterw.dfec = args.args.dfec;
      }
    }
    if (start > 0) {
      filterw.start = start;
    } else {
      filterw.start = 0;
    }
    //console.log("filter", filterw);
    /*const res = yield call(fetch,siteConfig.apiUrl,{method:'post',credentials: 'include',body:JSON.stringify({command:'Trackers',method:'getNotifications',...filterw,self:slf ? slf : 0,imei:im ? im : '',dfec:new Date(dy ? dy : '')})});*/
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getNotificationsMongo',
        ...filterw,
        self: slf ? slf : 0,
        imei: im ? im : '',
        matricula: matricula,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    //console.log("data notis ",data);
    data.noFilter = noFilter; //Para comprobar si le da todas las entities o no
    //  if (data.length > 0) data[0].data.imei = args.i_imei;
    if (slf === 1) {
      //console.log("s 1");
      yield put({ type: actions.NOTIFICATIONS_CLI_RECEIVED, data: data });
    } else {
      //console.log("s 0");
      yield put({ type: actions.NOTIFICATIONS_RECEIVED, data: data });
    }
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* fetchAlarms(args) {
  try {
    let filterw = getFilters(yield select(getProject));
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getTodayAlarms',
        filter: filterw,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    //  if (data.length > 0) data[0].data.imei = args.i_imei;
    yield put({ type: actions.ALARMS_RECEIVED, data: data });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* fetchCurrentAlarms(args) {
  try {
    let filterw = getFilters(yield select(getProject));
    if (args.args.length === 1) filterw.customer_id = args.args[0].customer_id;
    /*else {
        let ids = [];
        args.args.map(customer => {
            ids.push(customer.customer_id)
        })
        filterw.customer_id = ids
    }*/
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getCurrentAlarms',
        filter: filterw,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    //console.log("data", data)
    //  if (data.length > 0) data[0].data.imei = args.i_imei;
    yield put({ type: actions.CURRENT_ALARMS_RECEIVED, data: data });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* fetchNotifTypes(args) {
  try {
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getNotificationTypes',
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    data.map((d, idx) => {
      //para no cambiar backend modifico aquí los iconos de fontAwesome 6.
      if (d.icon === 'car-crash') d.icon = 'car-burst';
      if (d.icon === 'truck-loading') d.icon = 'truck-ramp-box';
      if (d.icon === 'map-marker-alt-slash') d.icon = 'location-dot-slash';
      if (d.icon === 'tachometer-alt-fastest') d.icon = 'gauge-max';
    });
    //  if (data.length > 0) data[0].data.imei = args.i_imei;

    yield put({ type: actions.NOTIF_TYPES_RECEIVED, data: data });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* fetchCentros(args) {
  try {
    let filterw = getFilters(yield select(getProject));
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getCentros',
        filter: filterw,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    yield put({ type: actions.CENTROS_RECEIVED, data: data });
    yield put({ type: actions.GET_GEOFENCES });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* fetchRiders(args) {
  try {
    let filterw = getFilters(yield select(getProject));
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getRiders',
        filter: filterw,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    yield put({ type: actions.RIDERS_RECEIVED, data: data });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* fetchDealers(data) {
  try {
    let filterw = getFilters(yield select(getProject));
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getDealers',
        filter: data.data ? data.data : filterw,
        session: localStorage.session,
      }),
    });
    const datas = yield call([res, res.json]);
    yield put({ type: actions.DEALERS_RECEIVED, data: datas });
  } catch (e) {
    yield put({ type: actions.GET_DEALERS_FAILED });
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* fetchTodayKms(args) {
  try {
    let filterw = getFilters(yield select(getProject));
    //console.log("filter", filterw);
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getTodayKms',
        filter: filterw,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    //console.log("data saga", data)
    yield put({ type: actions.TODAY_KMS_RECEIVED, data: data });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* updateGeofenceSaga(args) {
  //console.log("UPDATE GEO SAGA ",args);
  try {
    //console.info("saga", args)
    //if(args.args.filter.action) delete args.args.filter.action;
    /*const res = */ yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'setGeoFences',
        ...args.args,
        session: localStorage.session,
      }),
    });
    //const data = yield call([res, res.json]);

    yield put({ type: actions.GET_GEOFENCES, data: {} });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* updateGeofenceNameSaga(args) {
  // Esta función actualiza una geocerca ya creada a un cliente
  const datos = args.args;
  try {
    /*const res = */ yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'setGeoFences',
        clas: datos.clas,
        name: datos.name,
        customer_id: datos.csid,
        token: datos.token,
        dealerid: datos.dealerid,
        session: localStorage.session,
      }),
    });
    //const data = yield call([res, res.json]);
    yield put({ type: actions.GET_GEOFENCES, data: {} });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* createGeofenceSaga(args) {
  // Esta función crea una geocerca asociado a un cliente con nombre y clasificación, luego llama a la función update para actualizarla con el trazado
  const datos = args.args;
  //console.log("NEW GEO SAGA ", datos);
  try {
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'setGeoFences',
        clas: datos.clas,
        name: datos.name,
        customer_id: datos.customer_id,
        type: datos.type,
        lat: datos.lat,
        lng: datos.lng,
        rad: datos.rad,
        dealerid: datos.dealerid,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    datos.token = data.token;
    yield put({ type: actions.UPDATE_GEOFENCE, args: datos });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* createCentroSaga(args) {
  try {
    yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'actCentros',
        filter: args.args,
        session: localStorage.session,
      }),
    });
    yield put({ type: actions.GET_CENTROS, args: args.args });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
{
  /*Ambos son lo mismo???*/
}
function* updateCentroSaga(args) {
  try {
    let act = args.args.action;
    if (args.args.action) delete args.args.action;
    yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'actCentros',
        action: act,
        filter: args.args,
        session: localStorage.session,
      }),
    });
    yield put({ type: actions.GET_CENTROS, args: args.args });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* updateRiderSaga(args) {
  try {
    let action = args.args.action;
    if (args.args.action) delete args.args.action;
    yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'actRiders',
        action: action,
        filter: args.args,
        session: localStorage.session,
      }),
    });
    //const data = yield call([res, res.json]);
    yield put({ type: actions.GET_RIDERS, args: args.args });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* updateCentroGeofenceSaga(args) {
  try {
    /*const res = */ yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'actCentrosGeoFences',
        filter: args.args,
        session: localStorage.session,
      }),
    });
    //const data = yield call([res, res.json]);
    yield put({ type: actions.GET_CENTROS, args: args.args });
    yield put({ type: actions.GET_DEVICES });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* setRiderVehicleSaga(args) {
  //console.log("llega", args)
  try {
    /*const res = */ yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'setRiderToVeh',
        filter: args.args,
        session: localStorage.session,
      }),
    });
    //const data = yield call([res, res.json]);
    //console.log("saga setirder", data, args);
    yield delay(1000);
    yield put({ type: actions.GET_RIDERS });
    yield put({ type: actions.GET_DEVICES });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* setRidersCenterSaga(args) {
  try {
    /*const res = */ yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'setRidersCenter',
        filter: args.args,
        session: localStorage.session,
      }),
    });
    yield delay(1000);
    yield put({ type: actions.GET_RIDERS, args: args.args });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* removeAlertSaga(args) {
  try {
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'removeAlarm',
        imei: args.data.imei,
        codex: args.data.codex,
        date: args.data.date,
      }),
    });
    if (res.status === 200) {
      yield put({ type: actions.REMOVE_ALERT_SUCCESS, data: args.data });
      yield put({ type: actions.GET_DEVICES });
    }
    //yield put({ type: actions.GET_DEVICES });
    //yield put({ type: actions.GET_CURRENT_ALARMS, args: args.data.clientes });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* fetchGeofencesEvents(args) {
  try {
    let filterw = getFilters(yield select(getProject));
    //Filtro args
    if (args.args.imei) filterw.imei = args.args.imei;
    if (args.args.customer_id) filterw.customer_id = args.args.customer_id + '';
    if (args.args.conductor) filterw.rider = args.args.conductor + '';
    if (args.args.geofence) filterw.id = args.args.geofence + '';
    if (args.args.center) filterw.center = args.args.center;
    if (args.args.matricula) filterw.matricula = args.args.matricula;
    if (args.args.start) filterw.start = args.args.start;
    if (args.args.rows) filterw.rows = args.args.rows;

    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getGeoFencesEventList',
        filter: filterw,
        session: localStorage.session,
      }),
    });
    const data = yield call([res, res.json]);
    yield put({ type: actions.GEOFENCES_EVENTS_RECEIVED, data: data });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* getNotificationsGraficaSaga(args) {
  try {
    //console.log("SAGA");
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* getAddressMapboxSaga(args) {
  try {
    if (
      args.data[0].points &&
      args.data[2].points &&
      args.data[0].points.length > 0 &&
      args.data[2].points.length > 0
    ) {
      const puntoIni = args.data[0].points[0];
      const puntoFin = args.data[2].points[args.data[2].points.length - 1];
      const filePathIni = `https://api.mapbox.com/geocoding/v5/mapbox.places/${puntoIni.lon},${puntoIni.lat}.json?types=address&access_token=${siteConfig.mapboxToken}`;
      const resIni = yield call(fetch, filePathIni);
      const dataIni = yield call([resIni, resIni.json]);
      const filePathFin = `https://api.mapbox.com/geocoding/v5/mapbox.places/${puntoFin.lon},${puntoFin.lat}.json?types=address&access_token=${siteConfig.mapboxToken}`;
      const resFin = yield call(fetch, filePathFin);
      const dataFin = yield call([resFin, resFin.json]);
      if (dataIni && dataFin) {
        yield put({
          type: actions.ADDRESS_MAPBOX_RECEIVED,
          data: [
            dataIni,
            dataFin,
            args.data[1],
            args.data[3],
            args.data[4],
            args.data[5],
            args.data[6],
            args.data[7],
            args.data[8],
            args.data[9],
          ],
        });
      }
    }
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* getConfigNotificationsSaga({ data }) {
  try {
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getConfigNotif',
        session: localStorage.session,
        imei: data.imei,
        codex: data.codex, //Alert Code
        source: data.source, //Business type
        customer_id: data.customer_id,
        dealerid: data.dealerid,
        centerid: data.centerid,
        token: data.token, //Geofence token
        det: data.det, //Si quiere listar vehiculos o no, 0 o 1
      }),
    });
    const result = yield call([res, res.json]);
    yield put({ type: actions.GET_CONFIG_NOTIF_RECEIVED, data: result });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* setConfigNotificationsSaga({ data }) {
  try {
    yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'setConfigNotif',
        session: localStorage.session,
        imei: data.imei,
        codex: data.codex, //Alert Code
        source: data.source, //Business type
        customer_id: data.customer_id,
        dealerid: data.dealerid,
        centerid: data.centerid,
        token: data.token, //Geofence token
        entsal: data.entsal,
        notiftype: data.notiftype,
      }),
    });
    yield put({ type: actions.GET_CONFIG_NOTIF, data: { det: 1 } });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* setDashboardVisibleSaga({ data }) {
  try {
    if (data) {
      localStorage.setItem('dashboard-visible', data);
    }
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* setProfileSaga({ data }) {
  try {
    //console.info("DATA SET PROFILE", data)
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'setProfileUser',
        session: localStorage.session,
        mail: data.mail,
        phone: data.movil,
        name: data.name,
      }),
    });
    if (res && res.ok === true) {
      localStorage.setItem('lname', data.name);
      localStorage.setItem('movil', data.movil);
      localStorage.setItem('mail', data.mail);
      openNotification(
        'success',
        'Datos modificados',
        'Los datos se han cambiado correctamente'
      );
    } else {
      openNotification(
        'error',
        'Error al modificar',
        'Los datos no se han podido cambiar'
      );
    }
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* setProfileHTTPSaga({ data }) {
  try {
    //console.info("DATA SET PROFILE", data)
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'setConfigNotifHttp',
        session: localStorage.session,
        protocol: data.protocol,
        server: data.server,
        port: data.port,
        endpoint: data.endpoint,
      }),
    });
    if (res && res.ok === true) {
      //localStorage.setItem("name", data.name);
      //localStorage.setItem("movil", data.movil);
      //localStorage.setItem("mail", data.mail);
      openNotification(
        'success',
        'Datos modificados',
        'Los datos se han cambiado correctamente'
      );
    } else {
      openNotification(
        'error',
        'Error al modificar',
        'Los datos no se han podido cambiar'
      );
    }
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* getProfileHTTPSaga(args) {
  try {
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getConfigNotifHttp',
        session: localStorage.session,
      }),
    });
    const result = yield call([res, res.json]);
    yield put({ type: actions.GET_PROFILE_HTTP_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}
function* getTalleresSaga({ args }) {
  try {
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getOfficialServicesObj',
        session: localStorage.session,
        filter: args,
      }),
    });
    const result = yield call([res, res.json]);
    yield put({ type: actions.GET_TALLERES_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* getMapaAlertaSaga({ args }) {
  try {
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getImageMap',
        session: localStorage.session,
        ...args,
      }),
    });
    const result = yield call([res, res.json]);
    yield put({ type: actions.GET_MAPA_ALERTA_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* getGeofencesObjectsSaga({ args }) {
  let filter = {};
  if (args && args.customer_id) {
    filter.customer_id = args.customer_id;
  }
  try {
    const res = yield call(fetch, siteConfig.apiUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        command: 'Trackers',
        method: 'getGeoFencesObject',
        session: localStorage.session,
        filter: { ...filter },
        imei: args.imei,
      }),
    });
    const result = yield call([res, res.json]);
    yield put({ type: actions.GET_GEOFENCES_OBJECTS_SUCCESS, data: result });
  } catch (e) {
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* setBlqVehSaga({ args }) {
  try {
    if (args) {
      const res = yield call(fetch, siteConfig.apiUrl, {
        method: 'post',
        credentials: 'include',
        body: JSON.stringify({
          command: 'Trackers',
          method: 'sendMessage',
          ...args,
          source: 'Fleet Control',
        }),
      });
      if (res && res.status === 200) {
        yield delay(5000);
        yield put({ type: actions.GET_DEVICES });
      } else {
        openNotification(
          'error',
          'Error al bloquear/desbloquear vehículo',
          'En estos momentos no es posible realizar esa acción'
        );
      }
    }
  } catch (e) {
    openNotification(
      'error',
      'Error al bloquear/desbloquear vehículo',
      'En estos momentos no es posible realizar esa acción'
    );
    yield put({ type: actions.FETCH_FAILED, message: e.message });
  }
}

function* getDevices() {
  yield takeLatest(actions.GET_DEVICES, fetchDevices);
}
function* addDevices() {
  yield takeLatest(actions.ADD_DEVICES, addDevicesSaga);
}
function* getRiders() {
  yield takeLatest(actions.GET_RIDERS, fetchRiders);
}
function* getRoutes() {
  yield takeLatest(actions.GET_ROUTES, fetchRoutes);
}
function* getRoutesRiders() {
  yield takeLatest(actions.GET_ROUTES_RIDERS, fetchRoutesRiders);
}
function* getGeofences() {
  yield takeLatest(actions.GET_GEOFENCES, fetchGeofences);
}
function* getTodayKms() {
  yield takeLatest(actions.GET_TODAY_KMS, fetchTodayKms);
}
function* getDias() {
  yield takeLatest(actions.GET_DIAS, fetchDias);
}
function* getDiasMonthly() {
  yield takeLatest(actions.GET_DIAS_MONTHLY, fetchDiasMonthly);
}
function* getAcums() {
  yield takeLatest(actions.GET_ACUMS, fetchAcums);
}
function* getNotifications() {
  yield takeLatest(actions.GET_NOTIFICATIONS, fetchNotifications);
}
function* getAlarms() {
  yield takeLatest(actions.GET_ALARMS, fetchAlarms);
}
function* getCentros() {
  yield takeLatest(actions.GET_CENTROS, fetchCentros);
}
function* getDealers() {
  yield takeLatest(actions.GET_DEALERS, fetchDealers);
}
function* updateRider() {
  yield takeLatest(actions.UPDATE_RIDER, updateRiderSaga);
}
function* updateCentro() {
  yield takeLatest(actions.UPDATE_CENTRO, updateCentroSaga);
}
function* updateCentroGeofence() {
  yield takeLatest(actions.UPDATE_CENTRO_GEOFENCE, updateCentroGeofenceSaga);
}
function* updateGeofence() {
  yield takeLatest(actions.UPDATE_GEOFENCE, updateGeofenceSaga);
}
function* updateGeofenceName() {
  yield takeLatest(actions.UPDATE_GEOFENCE_NAME, updateGeofenceNameSaga);
}
function* createGeofence() {
  yield takeLatest(actions.CREATE_GEOFENCE, createGeofenceSaga);
}
function* createCentro() {
  yield takeLatest(actions.CREATE_CENTRO, createCentroSaga);
}
function* setRiderVehicle() {
  yield takeLatest(actions.SET_RIDER_VEHICLE, setRiderVehicleSaga);
}
function* setRidersCenter() {
  yield takeLatest(actions.SET_RIDERS_CENTER, setRidersCenterSaga);
}
function* removeAlert() {
  yield takeLatest(actions.REMOVE_ALERT, removeAlertSaga);
}

function* getNotificationsGrafica() {
  yield takeLatest(actions.GET_NOTIF_GRAFICAS, getNotificationsGraficaSaga);
}

function* getAddressMapbox() {
  yield takeLatest(actions.GET_ADDRESS_MAPBOX, getAddressMapboxSaga);
}

function* getNotifTypes() {
  yield takeLatest(actions.GET_NOTIF_TYPES, fetchNotifTypes);
}

function* getCurrentAlarms() {
  yield takeLatest(actions.GET_CURRENT_ALARMS, fetchCurrentAlarms);
}

function* getGeofencesEvents() {
  yield takeLatest(actions.GET_GEOFENCES_EVENTS, fetchGeofencesEvents);
}

function* setDeviceDealer() {
  yield takeLatest(actions.SET_DEALERS_VEH, setDeviceDealerSaga);
}

function* setRiderDealer() {
  yield takeLatest(actions.SET_DEALERS_RID, setRiderDealerSaga);
}

function* updateDealer() {
  yield takeLatest(actions.UPDATE_DEALER, updateDealerSaga);
}

function* getConfigNotifications() {
  yield takeLatest(actions.GET_CONFIG_NOTIF, getConfigNotificationsSaga);
}
function* setConfigNotifications() {
  yield takeLatest(actions.SET_CONFIG_NOTIF, setConfigNotificationsSaga);
}
function* setDashboardVisible() {
  yield takeLatest(actions.SET_DASHBOARD_VISIBLE, setDashboardVisibleSaga);
}
function* setProfile() {
  yield takeLatest(actions.SET_PROFILE, setProfileSaga);
}
function* setProfileHTTP() {
  yield takeLatest(actions.SET_PROFILE_HTTP, setProfileHTTPSaga);
}
function* getProfileHTTP() {
  yield takeLatest(actions.GET_PROFILE_HTTP, getProfileHTTPSaga);
}
function* getTalleres() {
  yield takeLatest(actions.GET_TALLERES, getTalleresSaga);
}
function* getMapaAlerta() {
  yield takeLatest(actions.GET_MAPA_ALERTA, getMapaAlertaSaga);
}
function* getGeoFencesObjects() {
  yield takeLatest(actions.GET_GEOFENCES_OBJECTS, getGeofencesObjectsSaga);
}
function* setBlqVeh() {
  yield takeLatest(actions.SET_BLQ_VEH, setBlqVehSaga);
}

export default function* rootSaga() {
  /*  yield all([
    fork(getDevices),
    fork(fetchDevices),
  ]);*/
  yield all([
    getDevices(),
    addDevices(),
    getRiders(),
    getGeofences(),
    getRoutes(),
    getRoutesRiders(),
    getDias(),
    getDiasMonthly(),
    getNotifications(),
    getAlarms(),
    updateGeofence(),
    updateGeofenceName(),
    createGeofence(),
    getCentros(),
    createCentro(),
    updateCentro(),
    updateRider(),
    updateCentroGeofence(),
    getTodayKms(),
    setRidersCenter(),
    setRiderVehicle(),
    removeAlert(),
    getNotificationsGrafica(),
    getAddressMapbox(),
    getNotifTypes(),
    getCurrentAlarms(),
    getGeofencesEvents(),
    getAcums(),
    getDealers(),
    setDeviceDealer(),
    setRiderDealer(),
    updateDealer(),
    getConfigNotifications(),
    setConfigNotifications(),
    setDashboardVisible(),
    setProfile(),
    setProfileHTTP(),
    getProfileHTTP(),
    getTalleres(),
    getMapaAlerta(),
    getGeoFencesObjects(),
    setBlqVeh(),
  ]);
}
