/*David 14 4 21*/
export function getTimeFormat(i_secs, tt) {
  // CAMBIO val1 val2 val3 val4 val5
  var res;
  // calculate (and subtract) whole days
  var delta = i_secs;
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds y aqui otro
  var seconds = (delta % 60).toFixed(0);

  minutes = lpad(minutes, 2, '0');
  seconds = lpad(seconds, 2, '0');

  switch (tt) {
    case 'hm':
      res = days * 24 + hours + 'h:' + minutes + 'm';
      break;
    case 'hms':
      res = hours + 'h:' + minutes + 'm:' + seconds + 's';
      break;
    default:
      res = days + 'd ' + hours + 'h:' + minutes + 'm:' + seconds + 's';
      break;
  }
  return res;
}

export function lpad(n, length, car) {
  n = n.toString();
  while (n.length < length) n = car + n;
  return n;
}

export function rpad(n, length, car) {
  n = n.toString();
  while (n.length < length) n = n + car;
  return n;
}

// y por aqui tambien

export function nvl(val1, val2) {
  var res;
  try {
    if (val1 || val1 === false) {
      res = val1;
    } else {
      res = val2;
    }
  } catch (e) {
    res = val2;
  }
  return res;
}
export function decimal(number) {
  return Number(number).toLocaleString('es-ES', { minimumFractionDigits: 2 });
}

export function average(arrayNumbers) {
  if (arrayNumbers.length === 0) {
    return 0;
  }
  return Math.round(arrayNumbers.reduce((a, b) => a + b) / arrayNumbers.length);
}

export function timeToHms(time) {
  var timesArray = time.split(':');
  var output = '';
  if (timesArray[0] !== '00') {
    output += `${timesArray[0]}h `;
  }
  if (timesArray[1]) {
    output += `${timesArray[1]}' `;
  }
  if (timesArray[2]) {
    output += `${timesArray[2]}''`;
  }
  return output;
}

export function toRadian(degree) {
  return (degree * Math.PI) / 180;
}

export function toDegree(radian) {
  return (radian * 180) / Math.PI;
}

export function middleOfTwoPoints(pointA, pointB) {
  var x = pointA.x + (pointB.x - pointA.x) * 0.5;
  var y = pointA.y + (pointB.y - pointA.y) * 0.5;
  return { x, y };
}

/*David 21-6-2021 Método creado para pasarle un número y comprobar si el usuario tiene ese rol en el persistente, se le puede pasar tanto string como numérico como array por si tiene más de un rol en específico*/
export function checkRole(...role) {
  let rls = localStorage.getItem('roles').split(',');
  //Si vienen en array
  if (Array.isArray(role)) {
    if (!role.every(v => rls.includes(v.toString()))) return false;
    else return true;
  }
  //Si vienen varios roles separados por coma
  if (rls.length > 1) {
    if (rls.find(n => n === role.toString())) {
      return true;
    }
  } else {
    //Si solo es un rol
    if (localStorage.getItem('roles').toString() === role.toString()) {
      return true;
    }
  }
  return false;
}

/*David 21-6-2021 Método para ordenar, si lo que se le pasa es un numérico en vez de un string marcar true el último parámetro*/
export function sortMethod(a, b, numerico) {
  if (a !== undefined && b !== undefined && a !== null && b !== null) {
    if (numerico) {
      return b - a;
    }
    if ((a === '' || a === '-') && b !== '' && b !== '-') return 1;
    if (a !== '' && a !== '-' && (b === '' || b === '-')) return -1;
    return a
      .toString()
      .toLowerCase()
      .localeCompare(b.toString().toLowerCase());
  } else return 0;
}

export function verMas(ctx, value) {
  localStorage.setItem('vermas', value);
  ctx.setState({
    vermas: localStorage.getItem('vermas'),
  });
}

/* LIMPIAR LAS COLUMNAS DE UNA TABLA */
export function clearColumns(columns, type) {
  let containsDifferents = columns.some(
    element =>
      !element.type.some(i => type.includes(i)) && element.type !== 'general'
  );
  while (containsDifferents) {
    let different = columns.findIndex(
      e => !e.type.some(i => type.includes(i)) && e.type !== 'general'
    );
    if (different !== -1) {
      columns.splice(different, 1);
    } else {
      containsDifferents = false;
    }
  }
  return columns;
}

export function filteredColumnName(defaultName, filteredName) {
  let name = defaultName;
  if (filteredName > '') {
    name = `[ ${filteredName} ]`;
  }
  return name;
}

//Tipos de notificaciones, array auxiliar
export let notificationTypes = [
  {
    title: 'Tipo de notificación',
    dataIndex: 'name',
    key: 'tiponot',
    align: 'left',
    type: ['tiponot'],
  },
  {
    title: 'MAIL',
    dataIndex: 'mail',
    key: 'mail',
    align: 'center',
    type: ['mail'],
    icon: 'envelope',
  },
  {
    title: 'SMS',
    dataIndex: 'sms',
    key: 'sms',
    align: 'center',
    type: ['sms'],
    icon: 'message-sms',
  },
  {
    title: 'HTTP',
    dataIndex: 'http',
    key: 'http',
    align: 'center',
    type: ['html'],
    icon: 'code',
  },
  {
    title: 'SCREEN',
    dataIndex: 'screen',
    key: 'screen',
    align: 'center',
    type: ['screen'],
    icon: 'messages',
  },
];

//Clasificación de geocercas
export const listpois = [
  {
    value: 'privada',
    icon: 'lock',
    name: 'Privada',
  },
  {
    value: 'seguridad',
    icon: 'camera-cctv',
    name: 'Seguridad',
  },
  {
    value: 'reparto',
    icon: 'truck',
    name: 'Reparto',
  },
  {
    value: 'parking',
    icon: 'square-parking',
    name: 'Parking',
  },
  {
    value: 'poi-casa',
    icon: 'house-chimney',
    name: 'POI - Casa',
  },
  {
    value: 'poi-puntocarga',
    icon: 'charging-station',
    name: 'POI - Punto de carga',
  },
  {
    value: 'poi-gasolinera',
    icon: 'gas-pump',
    name: 'POI - Gasolinera',
  },
  {
    value: 'poi-taller',
    icon: 'garage-car',
    name: 'POI - Taller',
  },
];
