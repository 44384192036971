import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import siteConfig from '@iso/config/site.config';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';

const history = createBrowserHistory();
const fakeApiCall = false; // auth0 or express JWT

//import { useDispatch } from 'react-redux';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ creds, payload }) {
    const { token } = payload;

    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        profile: 'Profile',
      });
    } else {
      if (fakeApiCall) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: 'secret token',
          profile: 'Profile',
        });
      } else {
        // console.log(creds);
        if (!creds.login || !creds.password) {
          yield put({ type: actions.LOGIN_ERROR });
        } else {
          //yield put({ type: actions.LOGIN_ERROR });
          loginUser(creds);
        }
      }
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield clearToken();
    history.push('/');
    window.location.href = '/';
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get('idToken');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    }
  });
}

export function loginUser(creds) {
  const config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    credentials: 'include',
    body: `login=${creds.login}&password=${creds.password}`,
  };

  async function fetchData() {
    const res = await fetch(siteConfig.loginUrl, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify(creds),
    });
    res.json().then(res => {
      //console.log(res);
      if (res.response == 'OK') {
        //console.log('voy a llamar a loginOK');
        //console.log(JSON.stringify(res));
        loginOK(res);
        //  const dispatch = useDispatch();
        /* yield put({
              type: actions.LOGIN_SUCCESS,
              token: 'secret token',
              profile: 'Profile',
            }); */
      }
    });
  }
  fetchData(creds);
  //return this.checkExpirity(response.token);
}

export function loginOK(arg) {
  //console.log("params login ok ", JSON.stringify(arg));
  localStorage.setItem('id_token', arg.token);
  localStorage.setItem('avatar', arg.avatar);
  localStorage.setItem('user', arg.user);
  localStorage.setItem('lname', arg.lname);
  localStorage.setItem('sname', arg.sname);
  localStorage.setItem('session', arg.session);
  localStorage.setItem('lc', arg.lc);
  localStorage.setItem('roles', arg.roles);
  localStorage.setItem('clis', arg.clis);
  let rls = arg.roles.split(','); //14/06/2021 Jose Manuel - Se comprueba los roles y si es 2 son los subagentes
  if (rls.length > 0) {
    if (rls.find(n => n === '2')) {
      localStorage.setItem('isDealer', true);
    } else {
      localStorage.setItem('isDealer', false);
    }
  } else {
    if (arg.roles === '2') {
      localStorage.setItem('isDealer', true);
    } else {
      localStorage.setItem('isDealer', false);
    }
  }
  localStorage.setItem('dealer_token', arg.dealer); //16/06/2021 Jose Manuel - Se añade una nueva propiedad al persistente para los usuarios dealer
  history.push('/dashboard');
  window.location.href = '/dashboard';
}

/*
  console.log('paso1');
  return dispatch => {
  console.log('paso2');
    
    // We dispatch requestLogin to kickoff the call to the API
   // dispatch(requestLogin(creds));
  console.log('paso3');

    return fetch('http://192.168.20.43:9000/login', config)
      .then(response => response.json().then(user => ({ user, response })))
      .then(({ user, response }) => {
        if (!response.ok) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(loginError(user.message));
          return Promise.reject(user);
        }
        // in posts create new action and check http status, if malign logout
        // If login was successful, set the token in local storage
        localStorage.setItem('id_token', user.id_token);
        // Dispatch the success action
        dispatch(receiveLogin(user));
        return Promise.resolve(user);
      })
      .catch(err => console.error('Error: ', err));
  };
  
}*/

export default function* rootSaga() {
  yield all([
    // fork(loginUser),
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
