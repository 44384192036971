export function getClientesFromDevices(devices) {
  var clientes = [];
  var idsClientes = [];
  if (Object.keys(devices).length > 0) {
    const devicesObj = Object.keys(devices).map(_ => {
      return devices[_];
    });
    devicesObj.forEach(device => {
      const cliente = {
        customer_id: device.customer_id,
        name: device.name,
        source: device.source,
      };
      if (!idsClientes.includes(cliente.customer_id)) {
        clientes.push(cliente);
        idsClientes.push(cliente.customer_id);
      }
    });
    clientes.sort((a, b) => {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });
  }
  return clientes;
}
