//console.log(process.env);
let obj = {};
if (process.env.NODE_ENV == 'development') {
  obj = {
    siteName: 'Flotas DEV',
    siteIcon: 'ion-flash',
    footerText: `Fleet Control DEV @ ${new Date().getFullYear()} Created by Sofgesa`,
    enableAnimatedRoute: false,
    loginUrl: 'https://dev-fleet-management.notabilus.com/login',
    apiUrl: 'https://dev-fleet-management.notabilus.com/API',
    socketUrl: 'https://dev-fleet-management.notabilus.com', //:81
    google: {
      analyticsKey: 'UA-xxxxxxxxx-1',
    },
    dashboard: '/dashboard',
    mapboxToken:
      'pk.eyJ1IjoibHVuYTcyIiwiYSI6ImNrY3NyeHl3ZDFybmUyeWx1cnVya3A0NjQifQ.-TgcZBpMB2yM63YktQEZpQ',
  };
} else {
  obj = {
    siteName: 'Flotas LolaMotorent.com',
    siteIcon: 'ion-flash',
    footerText: `Fleet Control @ ${new Date().getFullYear()} Created by Sofgesa`,
    enableAnimatedRoute: false,
    loginUrl: 'https://' + process.env.REACT_APP_BACKEND + '/login',
    apiUrl: 'https://' + process.env.REACT_APP_BACKEND + '/API',
    socketUrl: 'https://' + process.env.REACT_APP_BACKEND, // + ':81'
    google: {
      analyticsKey: 'UA-xxxxxxxxx-1',
    },
    dashboard: '/dashboard',
    mapboxToken:
      'pk.eyJ1IjoibHVuYTcyIiwiYSI6ImNrY3NyeHl3ZDFybmUyeWx1cnVya3A0NjQifQ.-TgcZBpMB2yM63YktQEZpQ',
  };
}

export default obj;
