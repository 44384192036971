import React from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import 'moment/locale/es';
import locale from 'antd/lib/locale/es_ES';

library.add(fas, far, fal, fab);

const App = () => (
  <Provider store={store}>
    <AppProvider>
      <ConfigProvider locale={locale}>
        <GlobalStyles />
        <Routes />
      </ConfigProvider>
    </AppProvider>
  </Provider>
);
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
